// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{background-color: #333;}


.full-height{ height: 100vh}

.scroll-box{ height: 200px; overflow-y: scroll; overflow-x: hidden;}

.test-box{
  height: 150px; 
  width: 250px; 
  border: 1px solid #333; 
  border-radius: 15px;
  margin: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,KAAK,sBAAsB,CAAC;;;AAG5B,cAAc,aAAa;;AAE3B,aAAa,aAAa,EAAE,kBAAkB,EAAE,kBAAkB,CAAC;;AAEnE;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":["body{background-color: #333;}\r\n\r\n\r\n.full-height{ height: 100vh}\r\n\r\n.scroll-box{ height: 200px; overflow-y: scroll; overflow-x: hidden;}\r\n\r\n.test-box{\r\n  height: 150px; \r\n  width: 250px; \r\n  border: 1px solid #333; \r\n  border-radius: 15px;\r\n  margin: 1em;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
