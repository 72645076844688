import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getPurchaseOrders = createAsyncThunk('getPurchaseOrders', async (data) =>{
  const po = await fetch(`${process.env.REACT_APP_API_URL}/get_purchase_orders`, {
    method: 'Get',
    headers: {"Content-Type": "application/json", "Token": data.token}
  })
  return po.json()
})

export const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState: {
    loading: false,
    data: [],
    error: null
  }, 
  reducers: {
    resetPo(state, action){
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseOrders.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }

})
export const {resetPo} = purchaseOrderSlice.actions;
export default purchaseOrderSlice.reducer