import { configureStore } from '@reduxjs/toolkit';

import sessionReducer from './SessionSlice'
import purchaseOrderSlice from './PurchaseOrderSlice';
import testSlice from './testSlicer';
import startSlice from './StartSlicer';
import ReportSlice from './ReportSlice';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    purchaseOrder: purchaseOrderSlice,
    start: startSlice,
    test: testSlice,
    report: ReportSlice
  }
})
