import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createReport = createAsyncThunk('createReport', async(data) => {
  const report = await fetch(`${process.env.REACT_APP_API_URL}/upload_report`, {
    method: 'get',
    headers: {"Content-Type": 'application/json', token: data.token}
  })

  return report.json()
})

export const ReportSlice = createSlice({
  name: 'ReportSlice',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {
    resetReport(state, action){
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createReport.fulfilled, (state, action) =>{
      state.data = action.payload
    })
  }
})
export const {resetReport} = ReportSlice.actions
export default ReportSlice.reducer;