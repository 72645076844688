import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Container, Card, Form, Button, ProgressBar, Spinner, Table, Alert} from 'react-bootstrap'

import LoginComponent from './login/LoginComponent'

import { logout } from '../state/SessionSlice'
import { translator } from '../scripts/translator'
import { startTest, testStarted } from '../state/StartSlicer'
import { getPurchaseOrders, resetPo } from '../state/PurchaseOrderSlice'
import { getTestProgression, resetTest} from '../state/testSlicer'
import { createReport, resetReport} from '../state/ReportSlice'

function MainComponent() {
  const dispatch = useDispatch()
  const [chinese, setChinese] = useState(true)
  const [po, setPo] = useState('')
  const [pols, setPols] = useState([])  
  const [pol, setPol] = useState(null)
  const [terminal, setTerminal] = useState([])
  const [status, setStatus] = useState([])
  const currentUser = useSelector(state => state.session.data)
  const purchaseOrders = useSelector(state => state.purchaseOrder.data)
  const test = useSelector(state => state.test.data)
  const testRunning = useSelector(state => state.start.data)
  const report = useSelector(state => state.report.data)


  useEffect(() => {
    if(Object.keys(currentUser).length !== 0 ){getPos()}
  },[currentUser])

  useEffect(() => {
    console.log(terminal)
  },[terminal])

  useEffect(() => {
    if(purchaseOrders.message){
      dispatch(logout())
      dispatch(resetPo())
    }else{
      if(po.length !== 0 && pol !== null){ findPols(po) }
    }
  }, [purchaseOrders])
  
  useEffect(() => {
    if(po.length !== 0){ findPols()}
    if(Array.isArray(test.outputs) && test.outputs.length !== 0){setTerminal(test.outputs)}
    if(Array.isArray(test.tests) && test.tests.length !== 0){setStatus(test.tests)}
    if(!!report.errors){
      let data = [...terminal]
      data.push({message: JSON.stringify(report.errors)})
      setTerminal(data)
    }
    if(!!report.message){
      getPos()
    }
  },[po, test, report])

  useEffect(() => {
    const interval = setInterval(() => {
      if(testRunning.status === true ){ getTestData() }
    },1000)
    return () => clearInterval(interval);
  },[testRunning])

  const getPos = () => dispatch(getPurchaseOrders({token: currentUser.token}))

  const getTestData = () => {
    dispatch(getTestProgression())
    dispatch(testStarted())
  }

  const calculateProgress = () => {
    let num = 0;
    for(let t of test.tests){
      if(t.test_progression === 3 || t.test_progression === 2 ){num++}
    }
    return 100 / test.tests.length * num
  }

  const checkFailOrFinish = (test) => {
    if(test.test_progression === 4){return({background: 'red', color: 'white'})}
    if(test.test_progression === 5){return({background: 'green', color: 'white'})}
  }

  const findPols = () => {
    const pols = []
    for(let key of Object.keys(purchaseOrders['purchase_orders'][po])){
      pols.push({id: key, ...purchaseOrders['purchase_orders'][po][key]})
    }
    setPols(pols)
  }

  const allTestsGood = () => {
    if(!!test.tests){
      const passedTests = status.filter(data => data.test_progression === 2)
      return passedTests.length === test.tests.length ? true : false;
    }
    return false
  }

  const runTest = () => dispatch(startTest({status: true, purchase_order: po, purchase_order_line:  pols[pol]['id'], spec: JSON.stringify(pols[pol]['wes_spec'])}))

  const sendReport = () => dispatch(createReport({token: currentUser.token}))
  
  const resetTestSetup = () =>{
    dispatch(resetReport())
    dispatch(resetTest())
    setTerminal([])
    setStatus([])
    getPurchaseOrders()
  }


  return (    
    <Container fluid className='full-height p-5'>
      {Object.keys(currentUser).length === 0  &&  <LoginComponent/>}
      {Object.keys(currentUser).length !== 0  && 
      <Card className='W-100'>
        <Card.Header className='d-flex justify-content-between'>
          <h5>{translator('Current Job', chinese)}</h5>
          <div className='d-flex'>
            <Form.Label>English </Form.Label>
            <Form.Check checked={chinese} onChange={e => setChinese(e.target.checked)} type='switch'/>
            <Form.Label>Chinese</Form.Label>
          </div>
        </Card.Header>
        <Card.Body>
          <div className='d-flex'>
            <div className='d-flex w-75 flex-column' >
              <div className='d-flex w-100'>
                <Card className='w-25 m-1'>
                  <Card.Header>{translator('Purchase order', chinese)}</Card.Header>
                  <Card.Body>
                    <span className='d-flex'>
                      <Button onClick={() => getPos()} variant='light' className='d-flex'><span className="material-symbols-outlined">refresh</span></Button>
                      { !!purchaseOrders.purchase_orders &&
                        <Form.Select onChange={e => setPo(e.target.value)}>
                          <option value="">{translator('Select purchase order', chinese)}</option>
                          {!!purchaseOrders.length !== 0  &&  Object.keys(purchaseOrders.purchase_orders).map(order => <option key={order} value={order}>{order}</option>)}
                        </Form.Select>
                      }
                    </span>
                    
                  </Card.Body>
                </Card>
                <Card className='w-75 m-1'>
                  <Card.Header>{translator('Purchase order line', chinese)}</Card.Header>
                  <Card.Body>
                    <Form.Select onChange={e => setPol(e.target.value)} disabled={po.length === 0}>
                      <option value="">{translator('Select purchase order line', chinese)}</option>
                      {po.length !== 0 &&  pols.map((line, index) => <option key={index + line.model} value={index} >{`${line.id}: ${line.model}`}</option>)}
                    </Form.Select> 
                  </Card.Body>
                </Card>
              </div>
              <div className='d-flex w-100'>
                <Card className='w-25 m-1'>
                  <Card.Header>{translator('Statistics', chinese)}</Card.Header>
                  <Card.Body>
                    <Table striped>
                      <tbody>
                        <tr>
                          <td><strong>{translator('Batteries in pol', chinese)}:</strong></td>
                          <td>{!!pols[pol] &&  pols[pol]['amount']}</td>
                        </tr>
                        <tr>
                          <td><strong>{translator('Batteries Done', chinese)}:</strong></td>
                          <td>{!!pols[pol] &&  pols[pol]['amount_completed']}</td>
                        </tr>
                        <tr>
                          <td><strong>{translator('First serial number', chinese)}:</strong></td>
                          <td>{!!pols[pol] &&  pols[pol]['serialStart']}</td>
                        </tr>
                        <tr>
                          <td><strong>{translator('Last serial number', chinese)}:</strong></td>
                          <td>{!!pols[pol] &&  pols[pol]['serialEnd']}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                <Card className='w-75 m-1'>
                  <Card.Header>{translator('QA Specification', chinese)}</Card.Header>
                  <Card.Body className='scroll-box'>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>{translator('name', chinese)}</th>
                          <th>{translator('Value', chinese)}</th>
                        </tr>
                      </thead>
                      {!!pols[pol] &&
                        <tbody>
                          {Object.keys(pols[pol]).map((param, index) => param !== 'wes_spec' ? <tr key={param}><td>{param}</td><td>{pols[pol][param]}</td></tr> : '')}
                        </tbody>
                      }
                    </Table>
                  </Card.Body>
                </Card>
              </div>
              <Card className='m-1'>
                <Card.Header>{translator('Status', chinese)}</Card.Header>
                <Card.Body>
                  {(!!test && !!test.tests) && 
                    <div className='w-100 m-1'> 
                      <ProgressBar now={calculateProgress()} variant='success'/> 
                    </div>
                  }
                  <div className='w-100 scroll-box d-flex flex-wrap m-1' style={{height: '430px'}}>
                    {status.map((test, index) => {return(
                      <div key={index + test} className='test-box p-1' style={checkFailOrFinish(test)}>
                        
                          <strong >{`${test.test_name}`}</strong>
                          {!!test.test_progression &&                           
                            <div>
                              {test.test_progression === 0 && <span className="material-symbols-outlined">hourglass_empty</span> }
                              {test.test_progression === 1 && <Spinner variant='primary' animation="border" size="sm" /> }
                              {test.test_progression === 2 && <span className="material-symbols-outlined" style={{color: 'green'}}>task_alt</span> }
                              {test.test_progression === 3 && <span className="material-symbols-outlined" style={{color: 'red'}}>error</span> }
                              {test.test_progression === 4 && <span className="material-symbols-outlined" style={{color: 'white'}}>thumb_down</span> }
                              {test.test_progression === 5 && <span className="material-symbols-outlined" style={{color: 'wite'}}>thumb_up</span> }
                            </div>
                          
                          }
                      </div>
                    )})}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className='d-flex w-25 flex-column'>
              <Card className='m-1'>
                <Card.Header>{translator('Logged in user', chinese)}</Card.Header>
                <Card.Body>
                  <h4>{currentUser.email}</h4>
                </Card.Body>
              </Card>
              <Card className='m-1'>
                <Card.Header>{translator('User input', chinese)}</Card.Header>
                <Card.Body className='d-flex flex-column'>
                  <div>
                    {(!!report.message && report.message === 'Report not uploaded') && <Alert variant='warning'>{report.message}</Alert>}
                    {(!!report.message && report.message === 'wes report saved') && <Alert variant='success'>{report.message}</Alert>}
                    {(!!report.message && report.message === 'could not save report') && <Alert variant='warning'>{report.message}</Alert>}
                    {(!!report.message || (!allTestsGood() && testRunning.status === 'False' && terminal.length !== 0)) && <Button className='w-100' onClick={e => resetTestSetup()}>New Test</Button>}
                  </div>
                  <br />
                  <p>{allTestsGood()}</p>
                  {(!!pol && testRunning.status === 'False' && !allTestsGood() && terminal.length === 0 && status.length === 0 ) && 
                    <Button size='xl' variant='info' className='m-1' onClick={e => runTest()}>{translator('Start test', chinese)}</Button>
                  }
                
                  {(testRunning.status === 'False' && allTestsGood() && !report.message) && <Button size='xl' variant='primary' onClick={() =>  sendReport()}  >{translator('Send report', chinese)}  </Button>}
                </Card.Body>
              </Card>
              <Card className='m-1'>
                <Card.Header>{translator('Terminal', chinese)}</Card.Header>
                <Card.Body className='scroll-box' style={{height: '560px', color: 'white', background: 'black'}}>
                  {terminal.map((output, index) => <p key={output.message + index}>{terminal[terminal.length -1 - index].message}</p>)}
                </Card.Body>
              </Card>
            </div>
          </div>
        </Card.Body>
      </Card>
    }
    </Container>
  )
}

export default MainComponent