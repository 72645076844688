import { chinese } from "../translations/chi"
import { english } from "../translations/eng"


export const translator = (string, chi) => {

  if(chi){
    return chinese[string]
  }else{
    return english[string]
  }
}
