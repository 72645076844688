import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const startTest = createAsyncThunk('startTest', async (data) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/start_test`, {
    method: 'POST',
    headers: {"Content-Type": 'application/json'},
    body: JSON.stringify(data)
  })

  return res.json()
})

export const testStarted = createAsyncThunk('testStarted', async (data) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/test_started`, {
    method: 'GET',
    headers: {"Content-Type": 'application/json'}
  })

  return res.json()
})

export const startSlice = createSlice({
  name: 'startSlice',
  initialState: {
    loading: false,
    data: {status: 'False'},
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(startTest.fulfilled, (state, action) => {
      state.data = action.payload
    })

    builder.addCase(testStarted.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default startSlice.reducer;