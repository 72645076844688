import React, {useState, useEffect} from 'react'
import {Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MainComponent from './components/MainComponent';

function App() {
  return (
    <div className="App">

      <Routes>
        <Route path='/' exact element={<MainComponent/>}/>
      </Routes>

    </div>
  );
}

export default App;
