import React, {useState, useEffect} from 'react'
import {useDispatch } from 'react-redux'
import {Container, Form, Card, Button} from 'react-bootstrap'

import {login} from '../../state/SessionSlice'

function LoginComponent() {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  const loginUser = () => dispatch(login({email, password}))

  const handleKeypress = (e) => {
    if(e.key === 'Enter'){
      loginUser();
    }
  }

  return (
    <Container className='d-flex align-items-center justify-content-center' style={{height: '100vh'}}>
      <Card className='w-50 d-flex justify-content-center align-items-center'>
        <h5 className='m-4'>Login</h5>
        <Form.Control className='m-4 w-75' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' />
        <Form.Control className='m-4 w-75' value={password} onKeyPress={e => handleKeypress(e)} onChange={e => setPassword(e.target.value)} placeholder='Password' type='password'/>
        <Button className='m-4' onClick={e => loginUser()}>Login</Button>

      </Card>
    </Container>
  )
}

export default LoginComponent