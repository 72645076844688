import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getTestProgression = createAsyncThunk('getTestProgression', async () => {
  const progress = await fetch(`${process.env.REACT_APP_API_URL}/test_progression`, {
    method: 'Get',
    headers: {"Content-Type": 'application/json'}
  })

  return progress.json()
})



export const testSlice = createSlice({
  name: 'testSlice',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers:{
    resetTest(state, action){
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTestProgression.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const {resetTest} = testSlice.actions;
export default testSlice.reducer