export const english = {
  'Current Job': 'Current Job',
  'Purchase order': 'Purchase order', 
  'Select purchase order': 'Select purchase order',
  'Purchase order line': 'Purchase order line',
  'Select purchase order line': 'Select purchase order line',
  'Statistics': 'Statistics',
  'Batteries in pol': 'Batteries in pol',
  'Batteries Done': 'Batteries Done',
  'First serial number': 'First serial number',
  'Last serial number': 'Last serial number',
  'QA Specification': 'QA Specification',
  'name': 'Name',
  'Value': 'Value',
  'Bms Model': 'Bms Model',
  'Disable BMS serial check': 'Disable BMS serial check',
  'SOC tolerance percentage': 'SOC tolerance percentage',
  'Cell health': 'Cell health',
  'Cell voltage diff': 'Cell voltage diff',
  'Design capacity': 'Design capacity',
  'Expected node id': 'Expected node id',
  'Firmware version': 'Firmware version', 
  'Internal resistance test': 'Internal resistance test',
  'Manual serial input': 'Manual serial input',
  'Qspec version': 'Qspec version',
  'Run full capacity update test': 'Run full capacity update test',
  'Run RTC test': 'Run RTC test',
  'Shunt test current': 'Shunt test current',
  'Skip custom parameters': 'Skip custom parameters',
  'Skip old SCO correction parameters': 'Skip old SCO correction parameters',
  'Target SOC': 'Target SOC', 
  'Target voltage': 'Target voltage',
  'Voltage calibration margin': 'Voltage calibration margin',
  'Voltage tolerance': 'Voltage tolerance',
  'Status': 'Status',
  'VoltMeter/LoadTester': 'VoltMeter/LoadTester',
  'Connected': 'Connected',
  'Connect device': 'Connect device',
  'Logged in user': 'Logged in user',
  'User input': 'User input', 
  'Start test': 'Start test',
  'Send report': 'Send report',
  'Terminal': 'Terminal'
}