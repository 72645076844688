export const chinese = {
  'Current Job': '目前的工作',
  'Purchase order': '订购单号码', 
  'Select purchase order': '选择采购订单',
  'Purchase order line': '采购订单行',
  'Select purchase order line': '选择采购订单行',
  'Statistics': '统计数据',
  'Batteries in pol': '采购订单行中的电池',
  'Batteries Done': '电池完成',
  'First serial number': '第一个序列号',
  'Last serial number': '最后的序列号',
  'QA Specification': '质量保证规范',
  'name': '姓名',
  'Value': '价值',
  'Bms Model': '电池管理系统型号',
  'Disable BMS serial check': '禁用电池管理系统串行检查',
  'SOC tolerance percentage': '充电状态容差百分比',
  'Cell health': '细胞健康',
  'Cell voltage diff': '电池电压差',
  'Design capacity': '设计能力',
  'Expected node id': '期望的节点 ID',
  'Firmware version': '固件版本', 
  'Internal resistance test': '内阻测试',
  'Manual serial input': '手动串行输入',
  'Qspec version': 'Q规格版本',
  'Run full capacity update test': '运行全容量更新测试',
  'Run RTC test': '运行 RTC 测试',
  'Shunt test current': '分流测试电流',
  'Skip custom parameters': '跳过自定义参数',
  'Skip old SCO correction parameters': '跳过旧的 SCO 校正参数',
  'Target SOC': '目标充电状态', 
  'Target voltage': '目标电压',
  'Voltage calibration margin': '电压校准余量',
  'Voltage tolerance': '耐电压',
  'Status': '地位',
  'VoltMeter/LoadTester': '电压表/负载测试仪',
  'Connected': '连接的',
  'Connect device': '连接设备',
  'Logged in user': '已登录用户',
  'User input': '用户输入', 
  'Start test': '开始测试',
  'Send report': '发送报告',
  'Terminal': '终端'
}